// 获取导航菜单
let indexmenu: string = 'api/index/menu';

// 轮播图
let carousel: string = "/api/index/carousel";

// 首页内容
let index_content: string = "/api/index/index_content";

// 会打工内容
let hdg_content: string = "/api/index/hdg_content";

// 乡村研究院内容
let research_content: string = "/api/index/research_content";

// 乡村研究院动态
let research_news: string = "/api/index/research_news";

// 乡村研究院智库
let research_thinktank: string = "/api/index/research_thinktank";

// 渠道合作内容
let cooperation_content: string = "/api/index/cooperation_content";

// 关于我们内容
let about_content: string = "/api/index/about_content";

// 合作留言
let message: string = "/api/index/message";

// 其他信息
let other_content: string = "/api/index/other_content";

// 研究院动态详情
let research_news_detail: string = "/api/index/research_news_detail";

const apiUrl = {
    indexmenu,
    carousel,
    index_content,
    hdg_content,
    research_content,
    research_news,
    research_thinktank,
    cooperation_content,
    about_content,
    message,
    other_content,
    research_news_detail
}

export default apiUrl;