import "./index.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, A11y } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { useEffect, useState } from "react";
import { requestGet } from "../../util/http";
import apiUrl from "../../util/apiUrl";
import apia from "../../util/api";
import { Image } from "antd";

interface Ipop {
  id?: string;
}
function Banner({ id = "首页" }: Ipop) {
  let [bann, setBann] = useState([]);
  useEffect(() => {
    let menu: any = sessionStorage.getItem("menu");
    let page: string = "";
    if (menu) {
      menu = JSON.parse(menu);
      menu.forEach((p: any) => {
        if (p.name === id) {
          page = p.page;
        }
      });
    }
    (async function fn() {
      let res = await requestGet(apiUrl.carousel, { page: page || "/" });
      if (res.code === 1) {
        res.data.forEach((p: any) => {
          p.image = apia.apiUrl + p.image;
        });
        setBann(res.data);
      }
    })();
  }, [id]);

  return (
    <Swiper
      modules={[Autoplay, Pagination, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={true}
      pagination={{ clickable: true }}
    >
      {bann.map((p: any) => {
        return (
          <SwiperSlide key={p.id}>
            <Image
              src={p.image}
              preview={false}
              className="imgobje"
              height="100%"
            ></Image>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Banner;
