import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routes from './view/view';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import { useEffect } from 'react';
import { requestGet } from './util/http';
import apiUrl from './util/apiUrl';

function App() {

  useEffect(() => {
    (async function fn() {
      let res = await requestGet(apiUrl.indexmenu, {});
      if (res.code === 1) {
        sessionStorage.setItem("menu", JSON.stringify(res.data));
      }
      // 其他信息
      let rea = await requestGet(apiUrl.other_content, {})
      if (rea.code === 1) {
        sessionStorage.setItem("qita", JSON.stringify(rea.data));
      }
    })()


  }, [])

  return (
    <ConfigProvider locale={zhCN}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
