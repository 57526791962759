import "./index.scss";
import { Image } from "antd";
import { useEffect, useState } from "react";
import apia from "../../util/api";
import { useNavigate } from "react-router-dom";

function Footer() {

    let navigate = useNavigate();


    let [dilist] = useState([
        {
            name: '惠打工',
            bool: false,
            path: "/hdg"
        },
        // {
        //     name: '惠打工大数据',
        //     bool: false
        // },
        {
            name: '乡村振兴研究院',
            bool: false,
            path: "/research"
        },
        {
            name: '渠道合作',
            bool: false,
            path: "/cooperation"
        },
        {
            name: '关于我们',
            bool: false,
            path: "/about"
        }
    ]);

    let [logo, setLogo] = useState<any>({}); // logo
    let [jian, setJian] = useState<any>({}); // 简介
    let [add, setAdd] = useState<any>({}); // 地址
    let [phone, setPhone] = useState<any>({}); // 手机号
    let [bei, setBei] = useState<any>({}); // 备案号
    let [zhong, setZhong] = useState<any>({}); // 公众号

    useEffect(() => {
        let qita: any = sessionStorage.getItem("qita");
        if (qita) {
            qita = JSON.parse(qita);
            qita.forEach((p: any) => {
                if (p.title === "公众号") {
                    p.value = apia.apiUrl + p.value;
                    setZhong(p);
                }
                if (p.title === "logo") {
                    p.value = apia.apiUrl + p.value;
                    setLogo(p);
                }
                if (p.title === "简介") {
                    setJian(p);
                }
                if (p.title === "联系电话") {
                    setPhone(p);
                }
                if (p.title === "备案号") {
                    setBei(p);
                }
                if (p.title === "企业地址") {
                    setAdd(p);
                }
            })
        }
    }, [])

    return (
        <div className="dibu_bcimg">
            <div className="di1200">
                <div className="dibu_flex">
                    <div className="dibu_left">
                        <div className="dibu_image">
                            <Image preview={false} src={logo ? logo.value : ""} width="100%" height="100%"></Image>
                        </div>
                        <div className="dibu_co AlibabaPuHuiTi">
                            {jian ? jian.value : ""}
                        </div>
                    </div>
                    <div className="dibu_center">
                        <div className="dibu_center_one">
                            <div className="dibu_center_t AlibabaPuHuiTi" onClick={() => { navigate("/") }}>首页</div>
                            {
                                dilist.map((p: any) => {
                                    return (
                                        <div key={p.name} className="dibu_center_one_co AlibabaPuHuiTi" onClick={() => { navigate(p.path) }}>{p.name}</div>
                                    )
                                })
                            }
                        </div>
                        <div className="dibu_center_one">
                            <div className="dibu_center_t AlibabaPuHuiTi">联系我们</div>
                            <div className="dibu_center_one_co AlibabaPuHuiTi">
                                地址：{add ? add.value : ""}
                            </div>
                            <div className="dibu_center_one_co AlibabaPuHuiTi">
                                联系电话：{phone ? phone.value : ""}
                            </div>
                        </div>
                    </div>
                    <div className="dibu_right">
                        <div className="dibu_zhong AlibabaPuHuiTi">
                            {zhong ? zhong.title : ""}
                        </div>
                        <div className="dibu_zhongim">
                            <Image src={zhong ? zhong.value : ""} width="100%" height="100%" preview={false}></Image>
                        </div>
                    </div>
                </div>
            </div>
            {/* 工商号 */}
            <div className="dibu_gong AlibabaPuHuiTi">
                <div className="dibu_left">
                    ©2022 by 广东潮工云科技有限公司 All Rights Reserved
                </div>
                <div className="dibu_right">
                    <a href="https://beian.miit.gov.cn">
                        {bei ? bei.value : ""}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer