import "./index.scss";
import { Image, Drawer } from "antd"; //message
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Header() {
  let navigate = useNavigate();
  let location = useLocation();

  // tabs
  let [heaList, setHeaList] = useState<
    { name: String; bool: Boolean; path?: String }[]
  >([
    {
      name: "首页",
      bool: true,
      path: "/",
    },
    {
      name: "惠打工",
      bool: false,
      path: "/hdg",
    },
    // {
    //     name: '惠打工大数据',
    //     bool: false
    // },
    {
      name: "乡村振兴研究院",
      bool: false,
      path: "/research",
    },
    {
      name: "渠道合作",
      bool: false,
      path: "/cooperation",
    },
    {
      name: "关于我们",
      bool: false,
      path: "/about",
    },
  ]);

  useEffect(() => {
    let list = heaList;
    list.forEach((p: any, i: number) => {
      p.bool = false;
      if (p.path === location.pathname) {
        p.bool = true;
      }
    });
    setHeaList(list);
  }, [heaList, location.pathname]);

  // 手机 抽屉
  let [bool, setBool] = useState(false);

  function go(path: string) {
    navigate(path);
  }

  return (
    <>
      <div className="hea_yin">
        <div className="w_1200">
          <div className="left">
            <div>
              <Image
                preview={false}
                src="https://hdg.huiworker.com/uploads/20220711/935b184c07469d23db6e427336fc3251.png"
                width={135}
                height={40}
              ></Image>
            </div>
            <div className="tabs">
              {heaList.map((p: any, i: number) => {
                return (
                  <div
                    key={i}
                    onClick={() => navigate(p.path)}
                    className={
                      p.bool ? "tab_act AlibabaPuHuiTi" : "AlibabaPuHuiTi"
                    }
                  >
                    {p.name}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="right">
            {/* <div className="he">合作加盟</div> */}
            <div
              className="iconge"
              onClick={() => {
                setBool(true);
              }}
            >
              <i className="iconfont icon-gengduo"></i>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title=" "
        placement="right"
        onClose={() => {
          setBool(false);
        }}
        visible={bool}
        width={320}
        closable={false}
      >
        {heaList.map((p: any, i: number) => {
          return (
            <div className="mar_t" key={i} onClick={() => go(p.path)}>
              {p.name}
            </div>
          );
        })}
        {/* <div className="mar_t">合作加盟</div> */}
      </Drawer>
    </>
  );
}

export default Header;
