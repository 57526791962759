// 腾讯地图api
export const MapKey = 'WX5BZ-EOYRS-KL7OR-6YOHY-5XRT2-VTBL4';

export function TMapGL(): Promise<void> {
    let wind: any = window
    if (wind.TMap) return Promise.resolve()
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://map.qq.com/api/gljs?v=1.exp&key=' + MapKey
        script.onerror = () => reject()
        script.onload = () => resolve()
        document.head.appendChild(script)
    })
}

// 时间戳转换
export const timestampToTime = (timestamp: number, index?: number) => {
    var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    var h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    // index 1 年月日 2年月日时分秒
    let time = index === 1 ? Y + M + D : Y + M + D + h + m + s
    return time;
}

// 当前日期
export const nowtime = () => {
    var date = new Date();
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    return Y + M
}

// 判断是 手机 pc 平板
export let os = function () {
    let ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        //   isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox &&
            /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc
    };
}();