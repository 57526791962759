import "./index.scss";
import Header from "../../component/header";
import Footer from "../../component/footer";
import { Image } from "antd";
import { requestGet } from "../../util/http";
import apiUrl from "../../util/apiUrl";
import { useEffect, useState } from "react";
import apia from "../../util/api";

import chao from "../../image/chao.png";
import hu from "../../image/hu.png";
import qiqa from "../../image/qi.png";

function Huida() {
  useEffect(() => {
    sessionStorage.setItem("bool", "1");
  }, []);

  let [hdg, setHdg] = useState<any>({});
  let [qi, setQi] = useState<any>({});
  let [shang, setShang] = useState<any>({});
  const fn = async () => {
    let res = await requestGet(apiUrl.hdg_content, {});
    if (res.code === 1) {
      res.data.forEach((p: any) => {
        if (p.bg_image) {
          p.bg_image = apia.apiUrl + p.bg_image;
        }
        if (p.qr_image) {
          p.qr_image = apia.apiUrl + p.qr_image;
        }
        if (p.title_two === "惠打工企业端") {
          setQi(p);
        }
        if (p.title_two === "惠打工直聘") {
          setHdg(p);
        }
        if (p.title_two === "惠打工商户端") {
          setShang(p);
        }
      });
    }
  };

  useEffect(() => {
    fn();
  }, []);

  return (
    <>
      <Header />
      {/* 用户端 */}
      <div className="hui_yong">
        <div className="hui1200">
          <div className="hui1200_left">
            <div className="hui_title AlibabaPuHuiTi">
              {hdg ? hdg.desc_two : ""}
            </div>
            <div className="hui_name AlibabaPuHuiTi-Medium">
              {hdg ? hdg.title_one : ""}
            </div>
            <div className="hui_title1 AlibabaPuHuiTi">
              {hdg ? hdg.desc_one : ""}
            </div>
            <div className="hui_val AlibabaPuHuiTi-Bold">
              {hdg ? hdg.title_two : ""}
            </div>
            <div className="hui_img">
              <Image
                src={hdg ? hdg.qr_image : ""}
                width="100%"
                height="100%"
                preview={false}
              ></Image>
            </div>
          </div>
          <div className="hui1200_right">
            <Image
              src={chao}
              width="100%"
              height="100%"
              preview={false}
            ></Image>
          </div>
        </div>
      </div>
      {/* 商家端 */}
      <div className="hui_shang">
        <div className="hui1200">
          <div className="hui1200_left">
            <div className="hui_title_co AlibabaPuHuiTi">
              {shang ? shang.desc_two : ""}
            </div>
            <div className="hui_name_co AlibabaPuHuiTi-Medium">
              {shang ? shang.title_one : ""}
            </div>
            <div className="hui_title_co AlibabaPuHuiTi">
              {shang ? shang.desc_one : ""}
            </div>
            <div className="hui_val_co AlibabaPuHuiTi-Bold">
              {shang ? shang.title_two : ""}
            </div>
            <div className="hui_img">
              <Image
                src={shang ? shang.qr_image : ""}
                width="100%"
                height="100%"
                preview={false}
              ></Image>
            </div>
          </div>
          <div className="hui1200_right">
            <Image
              src={hu}
              width="100%"
              height="auto"
              preview={false}
              className="imae"
            ></Image>
          </div>
        </div>
      </div>
      {/* 企业端 */}
      <div className="hui_qi">
        <div className="hui1200">
          <div className="hui1200_left">
            <div className="hui_title AlibabaPuHuiTi">
              {qi ? qi.desc_two : ""}
            </div>
            <div className="hui_name AlibabaPuHuiTi-Medium">
              {qi ? qi.title_one : ""}
            </div>
            <div className="hui_title AlibabaPuHuiTi">
              {qi ? qi.desc_one : ""}
            </div>
            <div className="hui_val AlibabaPuHuiTi-Bold">
              {qi ? qi.title_two : ""}
            </div>
            <div className="hui_img">
              <Image
                src={qi ? qi.qr_image : ""}
                width="100%"
                height="100%"
                preview={false}
              ></Image>
            </div>
          </div>
          <div className="hui1200_right">
            <Image
              src={qiqa}
              width="100%"
              height="100%"
              preview={false}
            ></Image>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Huida;
