let num: number = 0;
let api: string = "";
let apiUrl: string = "";

if (num === 0) {
  api = "https://www.huiworker.com";
  apiUrl = "https://www.huiworker.com";
} else if (num === 1) {
  api = "https://www.huiworker.com";
  apiUrl = "https://www.huiworker.com";
}

const apia = {
  api,
  apiUrl,
};

export default apia;
