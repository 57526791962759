import "./index.scss";
import Header from "../../component/header";
import Banner from "../../component/banner";
import Footer from "../../component/footer";
import { Image, Input, message } from "antd";
import { useEffect, useState } from "react";
import { requestGet, requestPost } from "../../util/http";
import apiUrl from "../../util/apiUrl";
import apia from "../../util/api";

import chaoshi from "../../image/chaoshi.png";
import an from "../../image/an.png";
import jian from "../../image/jian.png";
import mo1 from "../../image/mo1.png";
import mo2 from "../../image/mo2.png";
import mo3 from "../../image/mo3.png";
import tai1 from "../../image/tai1.png";
import tai2 from "../../image/tai2.png";
import tai3 from "../../image/tai3.png";

const { TextArea } = Input;

function Index() {
  useEffect(() => {
    sessionStorage.setItem("bool", "0");
  }, []);

  let [tabnum, settabnum] = useState(0); // 0 新人力 1 新模式 2新平台

  let [zailist] = useState([
    {
      name: "在线客服",
      val: "9:00 - 18:00",
    },
    {
      name: "企业地址",
      val: "广东省东莞市松山湖产业园光大We谷C2栋410",
    },
    {
      name: "联系电话",
      val: "18665136333",
    },
    {
      name: "邮政编码",
      val: "523000",
    },
  ]);

  let [name, setNmae] = useState("");
  function onchange(e: React.ChangeEvent<HTMLInputElement>) {
    setNmae(e.target.value);
  }

  let [phone, setPhone] = useState("");
  function onchangephone(e: React.ChangeEvent<HTMLInputElement>) {
    setPhone(e.target.value);
  }

  let [miao, setMiao] = useState("");
  function onchangemiao(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setMiao(e.target.value);
  }

  const submit = async () => {
    if (!name) {
      message.error("请输入姓名");
    } else if (!phone) {
      message.error("请输入电话号码");
    } else if (!miao) {
      message.error("请输入合作需求");
    } else {
      let res = await requestPost(apiUrl.message, {
        username: name,
        phone: phone,
        content: miao,
      });
      message.info(res.msg);
      if (res.code === 1) {
        setNmae("");
        setPhone("");
        setMiao("");
      }
    }
  };

  let [shou, setShou] = useState<any>({}); // 首页介绍
  let [xin, setXin] = useState<any>({}); // 核心优势
  let [chan, setChan] = useState<any>({}); // 产品服务
  let [zuo, setZuo] = useState<any>({}); // 合作客户
  let [bool, setBool] = useState(false);
  let shouin = async () => {
    let res = await requestGet(apiUrl.index_content, {});
    if (res.code === 1) {
      res.data.forEach((p: any) => {
        if (p.name === "首页") {
          p.content.forEach((s: any) => {
            if (s.image) {
              s.image = apia.apiUrl + s.image;
            }
          });
        } else if (p.name === "核心优势") {
          p.second_type.forEach((h: any) => {
            if (h.content.length !== 0) {
              h.content.forEach((x: any) => {
                x.image = apia.apiUrl + x.image;
              });
            }
          });
        } else if (p.name === "产品服务") {
          p.content.forEach((c: any) => {
            c.image = apia.apiUrl + c.image;
          });
        } else if (p.name === "合作客户") {
          p.content.forEach((z: any) => {
            z.image = apia.apiUrl + z.image;
          });
        }
      });
      setShou(res.data[0]);
      setXin(res.data[1]);
      setChan(res.data[2]);
      setZuo(res.data[3]);
    }
  };
  // 首页内容
  useEffect(() => {
    setBool(true);
    if (bool) {
      shouin();
    }
  }, [bool]);

  // 核心优势 tabs
  let [ren] = useState<{ name: string; img: string }[]>([
    {
      name: "更便捷",
      img: chaoshi,
    },
    {
      name: "更安全",
      img: an,
    },
    {
      name: "更健康",
      img: jian,
    },
  ]);

  let [mo] = useState<{ name: string; img: string }[]>([
    {
      name: "数字化",
      img: mo1,
    },
    {
      name: "智能化",
      img: mo2,
    },
    {
      name: "可视化",
      img: mo3,
    },
  ]);

  let [tai] = useState<{ name: string; img: string }[]>([
    {
      name: "资源共享",
      img: tai1,
    },
    {
      name: "ACN网络",
      img: tai2,
    },
    {
      name: "平台服务",
      img: tai3,
    },
  ]);

  return (
    <div className="">
      {/* 头部 */}
      <Header />
      {/* 轮播图 */}
      <Banner id="首页" />
      {/* 介绍 */}
      <div className="w1200 pt">
        <div className="left1">
          <div className="fona AlibabaPuHuiTi-Bold">
            {shou.content ? shou.content[0].title : ""}
          </div>
          <div className="coto AlibabaPuHuiTi">
            {shou.content ? shou.content[0].desc : ""}
          </div>
          <div className="fona AlibabaPuHuiTi-Bold">
            {shou.content ? shou.content[1].title : ""}
          </div>
          <div className="coto AlibabaPuHuiTi">
            {shou.content ? shou.content[1].desc : ""}
          </div>
        </div>
        <div className="chaohui">
          <Image
            preview={false}
            src={
              shou.content
                ? shou.content[1].image
                : "https://hdg.huiworker.com/uploads/20220711/02f044253e53ffe5087147b8801656cc.png"
            }
            width={"100%"}
            height={"100%"}
          ></Image>
        </div>
      </div>
      {/* 核心优势 */}
      <div className="hw1200">
        <div className="hexin AlibabaPuHuiTi-Bold">{xin ? xin.name : ""}</div>
        <div className="sanda AlibabaPuHuiTi">{xin ? xin.description : ""}</div>
        <div className="santabs AlibabaPuHuiTi">
          {/* <div className={tabnum === 0 ? 'tabsact' : 'tabsnoac'} onClick={() => { settabnum(0) }}>新人力</div>
                    <div className={tabnum === 1 ? 'tabsact' : 'tabsnoac'} onClick={() => { settabnum(1) }}>新模式</div>
                    <div className={tabnum === 2 ? 'tabsact' : 'tabsnoac'} onClick={() => { settabnum(2) }}>新平台</div> */}
          {xin.second_type
            ? xin.second_type.map((p: any, i: number) => {
                return (
                  <div
                    key={p.id}
                    className={tabnum === i ? "tabsact" : "tabsnoac"}
                    onClick={() => {
                      settabnum(i);
                    }}
                  >
                    {p.name}
                  </div>
                );
              })
            : ""}
        </div>
        {/* tabs切换内容 */}
        <div className="tabs_o">
          <div className="tabs_left">
            <div className="tabs_font AlibabaPuHuiTi-Bold">
              {xin.second_type
                ? xin.second_type[tabnum].content.length !== 0
                  ? xin.second_type[tabnum].content[0].title
                  : ""
                : ""}
            </div>
            <div className="borline"></div>
            <div className="tabs_coft AlibabaPuHuiTi">
              {xin.second_type
                ? xin.second_type[tabnum].content.length !== 0
                  ? xin.second_type[tabnum].content[0].desc
                  : ""
                : ""}
            </div>
            <div className="tabs_san AlibabaPuHuiTi-Bold">
              {tabnum === 0
                ? ren
                  ? ren.length !== 0
                    ? ren.map((p: any) => {
                        return (
                          <div className="sano" key={p.name}>
                            <div className="sano_im">
                              <Image
                                preview={false}
                                src={p.img}
                                width={"100%"}
                                height={"100%"}
                              ></Image>
                            </div>
                            {p.name}
                          </div>
                        );
                      })
                    : ""
                  : ""
                : tabnum === 1
                ? mo
                  ? mo.length !== 0
                    ? mo.map((p: any) => {
                        return (
                          <div className="sano" key={p.name}>
                            <div className="sano_im">
                              <Image
                                preview={false}
                                src={p.img}
                                width={"100%"}
                                height={"100%"}
                              ></Image>
                            </div>
                            {p.name}
                          </div>
                        );
                      })
                    : ""
                  : ""
                : tabnum === 2
                ? tai
                  ? tai.length !== 0
                    ? tai.map((p: any) => {
                        return (
                          <div className="sano" key={p.name}>
                            <div className="sano_im">
                              <Image
                                preview={false}
                                src={p.img}
                                width={"100%"}
                                height={"100%"}
                              ></Image>
                            </div>
                            {p.name}
                          </div>
                        );
                      })
                    : ""
                  : ""
                : ""}
            </div>
          </div>
          <div className="tabs_nei">
            <Image
              preview={false}
              src={xin.second_type ? xin.second_type[0].content[0].image : ""}
              width="100%"
              height="100%"
            ></Image>
          </div>
        </div>
      </div>
      {/* 产品服务 */}
      <div className="hw1200">
        <div className="chan AlibabaPuHuiTi-Bold">{chan ? chan.name : ""}</div>
        <div className="chansi AlibabaPuHuiTi-Bold">
          {chan ? chan.description : ""}
        </div>
        {/* 四大模板 */}
        <div className="chan_si">
          <div className="chan_left">
            {/* <div className="chan_flex">
                            <div className="chan_left_ai">
                                <Image src={aida} width={"100%"} height="100%" preview={false}></Image>
                            </div>
                            <div className="chan_kuan">
                                <div className="chan_font">AI+大数据招聘服务</div>
                                <div className="chan_hui">通过平台渠道、线上推广引流及ACN网络径获取有效精准
                                    求职者信息，针对不同客户进行人岗匹配，满足客户需求。
                                    提供了劳务派遣、人力资源供应、业务外包、人事代理、
                                    平台直聘等多种服务。</div>
                            </div>
                        </div>
                        <div className="chan_flex">
                            <div className="chan_left_ai">
                                <Image src={renzi} width={"100%"} height="100%" preview={false}></Image>
                            </div>
                            <div className="chan_kuan">
                                <div className="chan_font">人力资源供应链金融</div>
                                <div className="chan_hui">自主研发的SaaS软件服务，涵盖招聘、入职管理、借支、
                                    薪资发放、日结发薪、用户运营等功能，给客户提供人力
                                    资源全链路解决方案。
                                    惠打工直聘、惠打工机构端、惠打工企业端等三大平台</div>
                            </div>
                        </div> */}
            {chan.content
              ? chan.content.length === 0
                ? ""
                : chan.content.map((p: any, i: number) => {
                    return (
                      <div className={i < 5 ? "chan_flex" : "dino"} key={p.id}>
                        <div className="chan_left_ai">
                          <Image
                            src={p.image}
                            width={"100%"}
                            height="100%"
                            preview={false}
                          ></Image>
                        </div>
                        <div className="chan_kuan u-flex-1">
                          <div className="chan_font AlibabaPuHuiTi-Bold">
                            {p.title}
                          </div>
                          <div className="chan_hui AlibabaPuHuiTi">
                            {p.desc}
                          </div>
                        </div>
                      </div>
                    );
                  })
              : ""}
          </div>
        </div>
      </div>
      {/* 合作客户 */}
      <div className="widbco">
        <div className="hw1200">
          <div className="chan AlibabaPuHuiTi-Bold">{zuo ? zuo.name : ""}</div>
          <div className="chansi AlibabaPuHuiTi-Bold">
            {zuo ? zuo.description : ""}
          </div>
          {/* 企业图片 */}
          <div className="he_qi">
            {zuo.content
              ? zuo.content.length === 0
                ? ""
                : zuo.content.map((p: any, i: number) => {
                    return (
                      <div className="qi_zhi" key={p.id}>
                        <Image
                          src={p.image}
                          preview={false}
                          width="100%"
                          height="100%"
                        ></Image>
                      </div>
                    );
                  })
              : ""}
          </div>
        </div>
      </div>
      {/* 联系我们 */}
      <div className="bacimg">
        <div className="hw1200">
          <div className="lian_me AlibabaPuHuiTi-Bold">联系我们</div>
          <div className="lian_you AlibabaPuHuiTi-Bold">
            如有合作意向，可以留下你的电话号码和需求，方便我们联系。
          </div>
          <div className="lian_fen">
            <div className="lian_left">
              {zailist.map((p: any, i: number) => {
                return (
                  <div key={p.name} className={i < 3 ? "lian_mb" : ""}>
                    <div className="lian_zai AlibabaPuHuiTi-Bold">{p.name}</div>
                    <div className="lian_val AlibabaPuHuiTi">{p.val}</div>
                  </div>
                );
              })}
            </div>
            <div className="lian_right">
              {/* 称呼 号码 */}
              <div className="lian_flex">
                <div className="lian_cheng">
                  <div className="lian_cheng_max AlibabaPuHuiTi">称呼</div>
                  <div>
                    <Input
                      placeholder="请输入姓名"
                      bordered={false}
                      onChange={onchange}
                      value={name}
                    ></Input>
                  </div>
                </div>
                <div className="lian_phone">
                  <div className="lian_cheng_max AlibabaPuHuiTi">电话号码</div>
                  <div>
                    <Input
                      placeholder="请输入电话号码"
                      value={phone}
                      bordered={false}
                      maxLength={11}
                      onChange={onchangephone}
                    ></Input>
                  </div>
                </div>
              </div>
              {/* 符文本 */}
              <div className="lian_text">
                <div className="lian_cheng_max AlibabaPuHuiTi">合作需求</div>
                <div>
                  <TextArea
                    bordered={false}
                    showCount
                    maxLength={120}
                    placeholder="请描述你的需求"
                    onChange={onchangemiao}
                    autoSize
                    value={miao}
                  ></TextArea>
                </div>
              </div>
              {/* 提交按钮 */}
              <div className="lian_ti AlibabaPuHuiTi" onClick={submit}>
                确认提交
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 底部 */}
      <Footer></Footer>
    </div>
  );
}

export default Index;
