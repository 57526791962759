import "./index.scss";
import Header from "../../component/header";
import Footer from "../../component/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { Image } from "antd";
import { useEffect, useState } from "react";

import { requestGet } from "../../util/http";
import apiUrl from "../../util/apiUrl";
import apia from "../../util/api";

function Village() {

    let navigate = useNavigate()

    let { state: { id } }: any = useLocation();

    // 详情
    let [detail, setDetail] = useState<any>({});
    let [label, setLabel] = useState<any[]>([]);
    async function fn(id: number) {
        let res = await requestGet(apiUrl.research_news_detail, { id });
        if (res.code === 1) {
            if (res.data.image) {
                res.data.image = apia.apiUrl + res.data.image;
            }
            if (res.data.label) {
                setLabel(res.data.label.split(","));
            }
            setDetail(res.data);
        }
    }
    // 研究院动态
    let [donglist, setDonglist] = useState<any[]>([]); // 研究院动态
    function yan(num: number) {
        async function name() {
            let res = await requestGet(apiUrl.research_news, { page_num: num, page_size: 6 });
            if (res.code === 1) {
                res.data.data.forEach((p: any) => {
                    if (p.image) {
                        p.image = apia.apiUrl + p.image;
                    }
                    if (p.label) {
                        p.label = p.label.split(",");
                    }
                })
                setDonglist(res.data.data);
            }
        }
        name();
    }
    useEffect(() => {
        fn(id);
        yan(1);
        sessionStorage.setItem("bool", "2");
    }, [id])

    return (
        <>
            <Header></Header>
            <div className="cun1200">
                {/* 详情 */}
                <div className="cun_left">
                    <div className="cun_title AlibabaPuHuiTi-Bold">{detail ? detail.title : ""}</div>
                    <div className="cun_flex">
                        {
                            label.length === 0 ? "" : (
                                label.map((p: any) => {
                                    return (
                                        <div className="cun_box AlibabaPuHuiTi" key={p}>{p}</div>
                                    )
                                })
                            )
                        }
                    </div>
                    <div className="cun_flex1 AlibabaPuHuiTi">
                        <div className="cun_flex1_left">{detail ? detail.author : ""}</div>
                        <div>{detail ? detail.releasetime : ""}</div>
                    </div>
                    <div className="cun_line"></div>
                    <div className="cun_image">
                        <Image src={detail ? detail.image : ""} preview={false} width="100%" height="100%"></Image>
                    </div>
                    <div className="cun_con AlibabaPuHuiTi">
                        {
                            detail ? (
                                <div dangerouslySetInnerHTML={{ __html: detail.content }} />
                            ) : ""
                        }
                    </div>
                </div>
                {/* 研究院动态 */}
                <div className="cun_right">
                    <div className="cun_yan AlibabaPuHuiTi-Bold">研究院动态</div>
                    <div className="cun_line"></div>
                    <div className="cun_right_img" onClick={() => { fn(donglist[0].id) }}>
                        <Image src={donglist.length === 0 ? "" : donglist[0].image} preview={false} width="100%" height="100%"></Image>
                    </div>
                    <div className="cun_r_ti AlibabaPuHuiTi" onClick={() => { fn(donglist[0].id) }}>
                        {donglist.length === 0 ? "" : donglist[0].title}
                    </div>
                    <div className="cun_r_m AlibabaPuHuiTi" onClick={() => { fn(donglist[0].id) }}>
                        {donglist.length === 0 ? "" : donglist[0].releasetime}
                    </div>
                    <div className="cun_right_line"></div>
                    <div className="cun_right_flex">
                        {
                            donglist.length === 0 ? "暂无数据" : (
                                donglist.map((p: any, i: number) => {
                                    return (
                                        <div key={p.id} className={i > 0 ? "cun_right_box" : "cun_none"} onClick={() => { fn(p.id) }}>
                                            <div className="cun_right_fen">
                                                <div className="cun_right_img">
                                                    <Image src={p.image} preview={false} width="100%" height="100%"></Image>
                                                </div>
                                                <div className="cun_right_two">
                                                    <div className="cun_right_tti AlibabaPuHuiTi">
                                                        {p.title}
                                                    </div>
                                                    <div className="cun_right_time AlibabaPuHuiTi">
                                                        {p.releasetime}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="cun_dong_line"></div>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                    <div className="cun_blue AlibabaPuHuiTi" onClick={() => { navigate("/research/trends") }}>
                        查看更多{'>'}
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Village;