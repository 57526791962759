import "./index.scss";
import Header from "../../component/header";
import Footer from "../../component/footer";
import Banner from "../../component/banner";
import { useEffect, useState } from "react";
import { Image, Input, message } from "antd";
import { requestGet, requestPost } from "../../util/http";
import apiUrl from "../../util/apiUrl";
import apia from "../../util/api";

function Cooperation() {
  useEffect(() => {
    sessionStorage.setItem("bool", "3");
  }, []);

  // 联系我们
  let [name, setName] = useState("");
  let [phone, setPhone] = useState("");
  async function submit() {
    if (!name) {
      message.error("请输入姓名");
      return;
    }
    if (!phone) {
      message.error("请输入电话号码");
      return;
    }
    let res = await requestPost(apiUrl.message, {
      username: name,
      phone: phone,
    });
    message.info(res.msg);
    if (res.code === 1) {
      setName("");
      setPhone("");
    }
  }

  let [he, setHe] = useState<any>({}); // 合作流程
  let [fu, setFu] = useState<any>({}); // 服务优势
  let [you, setYou] = useState<any>({}); // 核心优势
  let [jia, setJia] = useState<any>({}); // 合作价值
  const fn = async () => {
    let res = await requestGet(apiUrl.cooperation_content, {});
    if (res.code === 1) {
      res.data.forEach((p: any) => {
        if (p.content.length !== 0) {
          p.content.forEach((a: any) => {
            if (a.image) {
              a.image = apia.apiUrl + a.image;
            }
          });
        }
        if (p.name === "合作流程") {
          setHe(p);
        }
        if (p.name === "服务优势") {
          setFu(p);
        }
        if (p.name === "核心优势") {
          setYou(p);
        }
        if (p.name === "合作价值") {
          setJia(p);
        }
      });
    }
  };
  useEffect(() => {
    fn();
  }, []);

  return (
    <>
      <Header></Header>
      {/* 招募计划 背景图 */}
      <Banner id="渠道合作"></Banner>
      {/* 合作价值 */}
      <div className="zhao1200">
        <div className="he_ti AlibabaPuHuiTi-Bold">{jia ? jia.name : ""}</div>
        <div className="he_duo AlibabaPuHuiTi-Bold">
          {jia ? jia.description : ""}
        </div>
        <div className="he_flex">
          {jia.content
            ? jia.content.length === 0
              ? ""
              : jia.content.map((p: any, i: number) => {
                  return (
                    <div
                      key={p.id}
                      className={i < 3 ? "he_box he_bo_line" : "he_box"}
                    >
                      <div className="he_img">
                        <Image
                          src={p.image}
                          width="100%"
                          height="100%"
                          preview={false}
                        ></Image>
                      </div>
                      <div className="he_na AlibabaPuHuiTi-Bold">{p.title}</div>
                      <div className="he_va AlibabaPuHuiTi">{p.desc}</div>
                    </div>
                  );
                })
            : ""}
        </div>
      </div>
      {/* 核心优势 */}
      <div className="zhaobcimg">
        <Image
          src={
            you.content
              ? you.content.length !== 0
                ? you.content[0].image
                : ""
              : ""
          }
          width="100%"
          height="100%"
          preview={false}
        ></Image>
      </div>
      {/* 服务优势 */}
      <div className="zhao1200">
        <div className="he_ti AlibabaPuHuiTi-Bold">{fu ? fu.name : ""}</div>
        <div className="he_duo AlibabaPuHuiTi-Bold">
          {fu ? fu.description : ""}
        </div>
        <div className="fu_flex">
          {fu.content
            ? fu.content.length === 0
              ? ""
              : fu.content.map((p: any) => {
                  return (
                    <div key={p.id} className="fu_box">
                      <div className="fu_img">
                        <Image
                          src={p.image}
                          width="100%"
                          height="100%"
                          preview={false}
                        ></Image>
                      </div>
                      <div className="fu_font AlibabaPuHuiTi">{p.desc}</div>
                    </div>
                  );
                })
            : ""}
        </div>
      </div>
      {/* 合作流程 */}
      <div className="zuobc">
        <div className="zhao1200">
          <div className="he_ti AlibabaPuHuiTi-Bold">{he ? he.name : ""}</div>
          <div className="he_duo AlibabaPuHuiTi-Bold">
            {he ? he.description : ""}
          </div>
          <div className="zuo_flex">
            {he.content
              ? he.content.length === 0
                ? ""
                : he.content.map((p: any) => {
                    return (
                      <div className="zuo_box" key={p.id}>
                        <div className="zuo_img">
                          <Image
                            src={p.image}
                            width="100%"
                            height="100%"
                            preview={false}
                          ></Image>
                        </div>
                        <div className="zuo_name AlibabaPuHuiTi">{p.desc}</div>
                      </div>
                    );
                  })
              : ""}
          </div>
        </div>
      </div>
      {/* 联系我们 */}
      <div className="lianxibc">
        <div className="zhao1200">
          <div className="lian_ti AlibabaPuHuiTi-Bold">联系我们</div>
          <div className="lian_flex">
            <div className="lian_ch">
              <div className="paco AlibabaPuHuiTi">称呼</div>
              <Input
                placeholder="请输入称呼"
                bordered={false}
                value={name}
                onChange={(e: any) => {
                  setName(e.target.value);
                }}
              ></Input>
            </div>
            <div className="lian_di">
              <div className="paco AlibabaPuHuiTi">电话</div>
              <Input
                placeholder="请输入电话号码"
                bordered={false}
                value={phone}
                maxLength={11}
                onChange={(e: any) => {
                  setPhone(e.target.value);
                }}
              ></Input>
            </div>
            <div className="lian_btn AlibabaPuHuiTi" onClick={submit}>
              确定
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Cooperation;
