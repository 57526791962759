import "./index.scss";
import Header from "../../component/header";
import Footer from "../../component/footer";
import { useEffect, useState } from "react";
import { Image, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import * as commit from "../../util/commit";
import type { PaginationProps } from 'antd';

import { requestGet } from "../../util/http";
import apiUrl from "../../util/apiUrl";
import apia from "../../util/api";

function Trends() {

    let navigate = useNavigate();

    // 分页
    // const [current, setCurrent] = useState(1);
    const onChange: PaginationProps['onChange'] = page => {
        // setCurrent(page);
        yan(page);
    };

    // 判断手机，pc，平板
    let [num, setNum] = useState(3); // 3 pc 2平板 1手机
    useEffect(() => {
        if (commit.os.isAndroid || commit.os.isPhone) {
            // 手机
            setNum(1)
        } else if (commit.os.isTablet) {
            // 平板
            setNum(2)
        } else if (commit.os.isPc) {
            // 电脑
            setNum(3)
        }
    }, [num])

    // 研究院动态
    let [donglist, setDonglist] = useState<any[]>([]); // 研究院动态
    let [total, setTotal] = useState<number>(0); // 总条数
    function yan(num: number) {
        async function name() {
            let res = await requestGet(apiUrl.research_news, { page_num: num, page_size: 9 });
            if (res.code === 1) {
                res.data.data.forEach((p: any) => {
                    if (p.image) {
                        p.image = apia.apiUrl + p.image;
                    }
                    if (p.label) {
                        p.label = p.label.split(",");
                    }
                })
                setDonglist(res.data.data);
                setTotal(res.data.total);
            }
        }
        name();
    }
    useEffect(() => {
        sessionStorage.setItem("bool", "2");
        yan(1);
    }, [])

    return (
        <>
            <Header></Header>
            <div className="taibc">
                <div className="tai1200">
                    <div className="tai_ti AlibabaPuHuiTi-Bold">研究院动态</div>
                    <div className="tai_flex">
                        {
                            donglist.length === 0 ? "暂无数据" : (
                                donglist.map((p: any) => {
                                    return (
                                        <div className="tai_box" key={p.id} onClick={() => { navigate("/research/village", { state: { id: p.id } }) }}>
                                            <div className="tia_img">
                                                <Image src={p.image} preview={false} width="100%" height="100%"></Image>
                                            </div>
                                            <div className="tai_box_flex">
                                                {
                                                    p.label ? (
                                                        p.label.length === 0 ? "" : (
                                                            p.label.map((p: any) => {
                                                                return (
                                                                    <div className="tai_biao AlibabaPuHuiTi-Bold" key={p}>{p}</div>
                                                                )
                                                            })
                                                        )
                                                    ) : ""
                                                }

                                            </div>
                                            <div className="tai_title AlibabaPuHuiTi-Bold">
                                                {p.title}
                                            </div>
                                            <div className="tai_time AlibabaPuHuiTi">{p.releasetime}</div>
                                        </div>
                                    )
                                })
                            )

                        }
                    </div>
                    {/* 分页 */}
                    <div className="tai_auto">
                        {
                            donglist.length === 0 ? "" : (
                                <Pagination defaultCurrent={1} responsive total={total} defaultPageSize={9} onChange={onChange} showSizeChanger={false} />
                            )
                        }
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}
export default Trends