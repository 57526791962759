import { useRoutes } from "react-router-dom";

// 路径
import Index from "./index/index"; // 首页
import Huida from "./hdg/index"; // 惠打工
import Aboutus from "./about"; // 关于我们
import Cooperation from "./cooperation";// 渠道合作
import Institute from "./research"; // 乡村振兴研究院
import Village from "./village"; // 研究院动态详情
import Trends from "./trends"; // 更多研究院动态

const Routes = () => {
    let list = [
        {
            path: "/",
            element: <Index></Index>
        },
        {
            path: "/hdg",
            element: <Huida></Huida>
        },
        {
            path: "/about",
            element: <Aboutus></Aboutus>
        },
        {
            path: "/cooperation",
            element: <Cooperation></Cooperation>
        },
        {
            path: "/research",
            element: <Institute></Institute>,
        },
        {
            path: "/research/village",
            element: <Village></Village>,
        },
        {
            path: "/research/trends",
            element: <Trends></Trends>,
        }
    ];
    let routes = useRoutes(list)
    return routes;
}

export default Routes;