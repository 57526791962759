import axios from "axios";
import apis from "./api";
// import md5 from 'js-md5';
const { api } = apis;
// axios 配置
axios.defaults.timeout = 5000; //响应时间
axios.defaults.headers['Content-Type'] = 'application/json'; //配置请求头

// 当实例创建时设置默认配置
axios.defaults.baseURL = api;

// 添加请求拦截器
axios.interceptors.request.use((config: any) => {
    // 在发送请求之前做些什么
    if (localStorage.getItem('md5token')) {
        config.headers["token"] = localStorage.getItem('md5token')
    }
    return config;
}, (error) => {
    // 隐藏加载
    // hideLoading()
    // 对请求错误做些什么
    return Promise.reject(error);
});

// http response 拦截器
axios.interceptors.response.use(
    response => {
        return response
    },
    //接口错误状态处理，也就是说无响应时的处理
    error => {
        return Promise.reject(error.response.status) // 返回接口返回的错误信息
    })

//封装下get
export function requestGet(url: string, params: any = {}): any {
    return new Promise((resolve, reject) => {
        // console.log("****************************",params);
        axios({
            method: 'get',
            url: url,
            params,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then(response => {
            if (response.status === 200) {
                //根据实际情况进行更改
                resolve(response.data)
            } else {
                reject(response.data)
            }
        }).catch((err: any) => {
            console.log('err', err);
        })
    })
}

export function requestPost(url: string, data: any): any {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then(response => {
            if (response.status === 200) {
                //根据实际情况进行更改
                resolve(response.data)
            } else {
                reject(response.data)
            }
        }).catch((err: any) => {
            console.log('err', err);
        })
    })
}