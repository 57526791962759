import "./index.scss";
import Header from "../../component/header";
import Footer from "../../component/footer";
import Banner from "../../component/banner";
import { useEffect, useState } from "react";
import { Image } from "antd";
import * as MapKey from "../../util/commit";

import { requestGet } from "../../util/http";
import apiUrl from "../../util/apiUrl";
import apia from "../../util/api";

function Aboutus() {

    useEffect(()=>{
        sessionStorage.setItem("bool", "4");
    },[])

    // 初始化地图
    let [bool, setBool] = useState<boolean>(false);
    function InMap() {

        // 获取经纬度
        let qita: any = sessionStorage.getItem("qita");
        let lat: string;
        let lng: string;
        if (qita) {
            qita = JSON.parse(qita);
            qita.forEach((p: any) => {
                if (p.title === "经度") {
                    lat = p.value;
                }
                if (p.title === "纬度") {
                    lng = p.value;
                }
            })
        }

        let wind: any = window
        // let TMap = wind.TMap
        MapKey
            .TMapGL() // 开始加载腾讯地图gl文件
            .then(() => { // 完成加载后，开始渲染地图
                let center = new wind.TMap.LatLng(lng, lat)
                let map = new wind.TMap.Map(document.querySelector('#myMap'), {
                    center,
                    zoom: 16.2,
                    baseMap: [
                        { type: 'vector' }, //设置矢量底图
                        // { type: 'traffic' }, //设置路况底图
                    ],
                })
                // 创建并初始化MultiMarker
                var markerLayer = new wind.TMap.MultiMarker({
                    map: map, // 指定地图容器
                    // 样式定义
                    styles: {
                        // 创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
                        myStyle: new wind.TMap.MarkerStyle({
                            width: 25, // 点标记样式宽度（像素）
                            height: 35, // 点标记样式高度（像素）
                            // 焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                            anchor: { x: 16, y: 32 }
                        })
                    },
                    // 点标记数据数组
                    geometries: [
                        {
                            id: '1', // 点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                            styleId: 'myStyle', // 指定样式id
                            position: new wind.TMap.LatLng(lng, lat), // 点标记坐标位置
                            properties: {
                                // 自定义属性
                                title: 'marker1'
                            }
                        }
                    ]

                })
                console.log("???", markerLayer);
            })
    }

    // 数据
    let [qiye, setQiye] = useState<any>({});
    let [guanli, setGuanli] = useState<any>({});
    let [xiang, setXiang] = useState<any>({});
    const fn = async () => {
        let res = await requestGet(apiUrl.about_content, {})
        if (res.code === 1) {
            res.data.forEach((p: any) => {
                if (p.content.length !== 0) {
                    p.content.forEach((a: any) => {
                        if (a.image) {
                            a.image = apia.apiUrl + a.image;
                        }
                    })
                }
                if (p.name === "企业文化") {
                    setQiye(p);
                }
                if (p.name === "管理团队") {
                    setGuanli(p);
                }
                if (p.name === "相关资质") {
                    setXiang(p);
                }
            })
        }
    }
    useEffect(() => {
        setBool(true)
        if (bool) {
            InMap();
            fn();
        }
    }, [bool])



    return (
        <>
            <Header></Header>
            {/* 首---背景 */}
            <Banner id="关于我们"></Banner>
            {/* 企业文化 */}
            <div className="guan1200 padd">
                <div className="qi_font AlibabaPuHuiTi">{qiye ? qiye.name : ""}</div>
                <div className="qi_min AlibabaPuHuiTi-Bold w42">
                    {qiye ? qiye.description : ""}
                </div>
                <div className="qi_san">
                    {
                        qiye.content ? (
                            qiye.content.length !== 0 ? (
                                qiye.content.map((p: any) => {
                                    return (
                                        <div key={p.id} className="qi_on">
                                            <div className="sanimg">
                                                <Image src={p.image} width="100%" height="100%" preview={false}></Image>
                                            </div>
                                            <div className="qi_na AlibabaPuHuiTi-Medium">{p.title}</div>
                                            <div className="qi_va AlibabaPuHuiTi">{p.desc}</div>
                                        </div>
                                    )
                                })
                            ) : ""
                        ) : ""
                    }
                </div>
            </div>
            {/* 管理团队 */}
            <div className="bcfb">
                <div className="guan1200 padd">
                    <div className="qi_font AlibabaPuHuiTi-Bold">{guanli ? guanli.name : ""}</div>
                    <div className="qi_min AlibabaPuHuiTi-Bold">
                        {guanli ? guanli.description : ""}
                    </div>
                    <div className="tuan_zhan">
                        <div className="tuan_zhan_left">
                            <div className="tuan_zhan_name AlibabaPuHuiTi-Bold">{guanli.content ? guanli.content[0].title : ""}</div>
                            <div className="tuan_zhan_jie AlibabaPuHuiTi-Medium">{guanli.content ? guanli.content[0].label : ""}</div>
                            <div className="tuan_zhan_co AlibabaPuHuiTi">
                                {guanli.content ? guanli.content[0].desc : ""}
                            </div>
                        </div>
                        <div className="tuan_zhan_right">
                            <Image src={guanli.content ? guanli.content[0].image : ""} preview={false} width="100%" height="100%"></Image>
                        </div>
                    </div>
                    <div className="tuan_fen">
                        {
                            guanli.content ? (
                                guanli.content.length === 0 ? "2" : (
                                    guanli.content.map((p: any, i: number) => {
                                        return (
                                            <div key={p.id} className={i > 0 ? "tuan_fen_o" : "dinoab"}>
                                                <div className="tuan_fen_name AlibabaPuHuiTi-Bold">{p.title}</div>
                                                <div className="tuan_fen_po AlibabaPuHuiTi-Medium">{p.label}</div>
                                                <div className="tuan_fen_jie AlibabaPuHuiTi">{p.desc}</div>
                                            </div>
                                        )
                                    })
                                )
                            ) : "1"
                        }
                    </div>
                </div>
            </div>
            {/* 相关资质 */}
            <div className="guan1200 padd">
                <div className="qi_font AlibabaPuHuiTi">{xiang ? xiang.name : ""}</div>
                <div className="qi_min AlibabaPuHuiTi-Bold">{xiang ? xiang.description : ""}</div>
                <div className="zi_list">
                    {
                        xiang.content ? (
                            xiang.content.length === 0 ? "" : (
                                xiang.content.map((p: any, i: number) => {
                                    return (
                                        <div key={p.id} className="zi_o">
                                            {/* {i === 1 ? "zi_img zi_img_route" : "zi_img"} */}
                                            <div className="zi_img">
                                                <Image src={p.image} width="100%" height="100%" preview={false}></Image>
                                            </div>
                                            <div className="zi_name AlibabaPuHuiTi">
                                                {p.title}
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        ) : ""
                    }
                </div>
            </div>
            {/* 公司地址 */}
            <div className="guan1200 padda">
                <div className="qi_font AlibabaPuHuiTi">公司地址</div>
                <div className="map" id="myMap"></div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Aboutus;