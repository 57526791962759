import "./index.scss";
import Header from "../../component/header";
import Footer from "../../component/footer";
import Banner from "../../component/banner";
import { Image } from "antd";
import { useEffect, useState } from "react";
import * as commit from "../../util/commit";
// import { useNavigate } from "react-router-dom";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import you from "../../image/you.png";
import { requestGet } from "../../util/http";
import apiUrl from "../../util/apiUrl";
import apia from "../../util/api";

function Institute() {
  // const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem("bool", "2");
  }, []);

  // 判断手机，pc，平板
  let [num, setNum] = useState(3); // 3 pc 2平板 1手机
  useEffect(() => {
    if (commit.os.isAndroid || commit.os.isPhone) {
      // 手机
      setNum(1);
    } else if (commit.os.isTablet) {
      // 平板
      setNum(2);
    } else if (commit.os.isPc) {
      // 电脑
      setNum(3);
    }
  }, [num]);

  // 跳转乡村详情
  // function getgo(id: number) {
  //     navigate("/research/village", { state: { id } })
  // }

  // 数据
  let [jie, setJie] = useState<any>({}); // 乡村振兴研究院
  let [yewu, setYewu] = useState<any>({}); // 业务领域
  let [tuan, setTuan] = useState<any>({}); // 管理团队
  let [cai, setCai] = useState<any>({}); // 研究院风采
  // let [dong, setDong] = useState<any>({}); // 研究院动态
  let [zhuan, setZhuan] = useState<any>({}); // 专家智库
  // 乡村振兴研究院
  const fn = async () => {
    let res = await requestGet(apiUrl.research_content, {});
    if (res.code === 1) {
      res.data.forEach((p: any) => {
        if (p.content.length !== 0) {
          p.content.forEach((a: any) => {
            if (a.image) {
              a.image = apia.apiUrl + a.image;
            }
          });
        }
        if (p.name === "乡村振兴研究院") {
          setJie(p);
        }
        if (p.name === "业务领域") {
          setYewu(p);
        }
        if (p.name === "管理团队") {
          setTuan(p);
        }
        if (p.name === "专家智库") {
          setZhuan(p);
        }
        if (p.name === "研究院风采") {
          setCai(p);
        }
        // if (p.name === "研究院动态") {
        //     setDong(p);
        // }
      });
    }
  };
  // 研究院动态
  // let [donglist, setDonglist] = useState<any[]>([]); // 研究院动态
  // const yan = async () => {
  //     let res = await requestGet(apiUrl.research_news, { page_num: 1, page_size: 6 });
  //     if (res.code === 1) {
  //         res.data.data.forEach((p: any) => {
  //             if (p.image) {
  //                 p.image = apia.apiUrl + p.image;
  //             }
  //             if (p.label) {
  //                 p.label = p.label.split(",");
  //             }
  //         })
  //         setDonglist(res.data.data)
  //     }
  // }
  // 乡村振兴智库
  let [zhuanlist, setZhuanlist] = useState<any[]>([]);
  const zhi = async () => {
    let res = await requestGet(apiUrl.research_thinktank, {
      page: 1,
      page_size: 4,
    });
    if (res.code === 1) {
      res.data.data.forEach((p: any) => {
        if (p.image) {
          p.image = apia.apiUrl + p.image;
        }
      });
      setZhuanlist(res.data.data);
    }
  };
  useEffect(() => {
    fn();
    // yan();
    zhi();
  }, []);

  return (
    <>
      <Header></Header>
      <Banner id="乡村振兴研究院"></Banner>
      {/* 乡村振兴研究院 */}
      <div className="yan1200 upre">
        <div className="yan_box">
          <div className="yan_font AlibabaPuHuiTi-Bold">
            {jie ? jie.name : ""}
          </div>
          <div className="yan_co AlibabaPuHuiTi-Bold">
            {jie.content ? (
              jie.content.length === 0 ? (
                ""
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: jie.content[0].desc }}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="yan_ch">
          <div className="yan_le AlibabaPuHuiTi-Medium">
            <div className="yan_max">了解更多关于我们？</div>
            <div className="yan_min">WANT TO KNOW MORE ABOUT US?</div>
          </div>
          <div className="yan_img">
            <Image src={you} width="100%" height="100%" preview={false}></Image>
          </div>
        </div>
        <div className="yan_right">
          <Image
            src={
              jie.content
                ? jie.content.length === 0
                  ? ""
                  : jie.content[0].image
                : ""
            }
            width="100%"
            height="100%"
            preview={false}
          ></Image>
        </div>
      </div>
      {/* 业务领域 */}
      <div className="yan1200">
        <div className="ye_ti AlibabaPuHuiTi-Bold">{yewu ? yewu.name : ""}</div>
        <div className="ye_zh AlibabaPuHuiTi-Bold">
          {yewu ? yewu.description : ""}
        </div>
        <div className="ye_flex AlibabaPuHuiTi-Medium">
          {yewu.content
            ? yewu.content.length === 0
              ? ""
              : yewu.content.map((p: any, i: number) => {
                  return (
                    <div
                      className={
                        i === 1 || i === 3 || i === 4 || i === 6
                          ? "ye_box_hoc yebox"
                          : "ye_box_img yebox"
                      }
                      key={p.id}
                    >
                      <div className="ye_box_img_ti">{p.title}</div>
                      <div className="ye_box_img_o">
                        <Image
                          src={p.image}
                          width="100%"
                          height="100%"
                          preview={false}
                        ></Image>
                      </div>
                    </div>
                  );
                })
            : ""}
        </div>
      </div>
      {/* 管理团队 */}
      <div className="yan1200">
        <div className="guan_font te AlibabaPuHuiTi-Bold">
          {tuan ? tuan.name : ""}
        </div>
        <div className="guan_flex">
          <div className="guan_left">
            <Image
              src={
                tuan.content
                  ? tuan.content.length === 0
                    ? ""
                    : tuan.content[0].image
                  : ""
              }
              preview={false}
              width="100%"
              height="100%"
            ></Image>
          </div>
          <div className="guan_right">
            <div className="guan_font AlibabaPuHuiTi-Bold">
              {tuan.content
                ? tuan.content.length === 0
                  ? ""
                  : tuan.content[0].title
                : ""}
            </div>
            <div className="guan_co AlibabaPuHuiTi">
              {tuan.content ? (
                tuan.content.length === 0 ? (
                  ""
                ) : (
                  <div
                    dangerouslySetInnerHTML={{ __html: tuan.content[0].desc }}
                  />
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      {/* 专家智库 */}
      <div className="yan1200">
        <div className="guan_font p30 AlibabaPuHuiTi-Bold">
          {zhuan ? zhuan.name : ""}
        </div>
        <div className="zhuan_flex">
          {zhuanlist
            ? zhuanlist.length === 0
              ? ""
              : zhuanlist.map((p: any) => {
                  return (
                    <div key={p.id} className="zhuan_box">
                      <div className="zhuan_box_img">
                        <Image
                          src={p.image}
                          preview={false}
                          width="100%"
                          height="100%"
                        ></Image>
                      </div>
                      <div className="zhuan_box_w">
                        <div className="zhuan_box_t">
                          <h3>{p.username}</h3>
                        </div>
                        <div className="zhuan_box_t_o">
                          <div
                            dangerouslySetInnerHTML={{ __html: p.social_title }}
                          ></div>
                        </div>
                        <div className="zhuan_box_detail">
                          <div
                            dangerouslySetInnerHTML={{ __html: p.project }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  );
                })
            : ""}
        </div>
      </div>
      {/* 研究院风采 */}
      <div className="yan1200">
        <div className="guan_font p30 AlibabaPuHuiTi-Bold">
          {cai ? cai.name : ""}
        </div>
        <div className="yan_swiper">
          <Swiper
            modules={[A11y, Navigation]}
            spaceBetween={50}
            slidesPerView={num}
            navigation
          >
            {cai.content
              ? cai.content.length === 0
                ? ""
                : cai.content.map((p: any) => {
                    return (
                      <SwiperSlide key={p.id}>
                        <Image
                          src={p.image}
                          preview={false}
                          width="100%"
                          height="100%"
                        ></Image>
                      </SwiperSlide>
                    );
                  })
              : ""}
          </Swiper>
        </div>
      </div>
      {/* 研究院动态 */}
      {/* <div className="yandongbc">
                <div className="yan1200">
                    <div className="p30 guan_font AlibabaPuHuiTi-Bold">{dong ? dong.name : ""}</div>
                    <div className="dong_flex">
                        {
                            donglist.length === 0 ? "暂无数据" : (
                                donglist.map((p: any) => {
                                    return (
                                        <div className="dong_box" key={p.id} onClick={() => getgo(p.id)}>
                                            <div className="dong_img">
                                                <Image src={p.image} width="100%" height="100%" preview={false}></Image>
                                            </div>
                                            <div className="dong_box_flex">
                                                {
                                                    p.label ? (
                                                        p.label.length === 0 ? "" : (
                                                            p.label.map((p: any) => {
                                                                return (
                                                                    <div className="dong_box_flex_box AlibabaPuHuiTi-Bold" key={p}>{p}</div>
                                                                )
                                                            })
                                                        )
                                                    ) : ""
                                                }
                                            </div>
                                            <div className="dong_va AlibabaPuHuiTi-Bold">
                                                {p.title}
                                            </div>
                                            <div className="dong_ti AlibabaPuHuiTi">
                                                {p.releasetime}
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>
                    {
                        donglist.length === 0 ? "" : (
                            <div className="dong_cha AlibabaPuHuiTi-Bold" onClick={() => { navigate("/research/trends") }}>查看更多</div>
                        )
                    }
                </div>
            </div> */}
      <Footer></Footer>
    </>
  );
}
export default Institute;
